import { forumApi } from "@/api/ilect-openapi-configuration";
import { ForumStats } from "@/openapi-ts";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useNotificationsStore = defineStore("notifications", () => {
  const forumStats = ref<ForumStats>({
    unread_forum_count: 0,
    unread_announcements_count: 0,
    unread_total_count: 0,
  });

  const showNotifications = ref(false);

  const fetchForumStats = async (course: string | undefined) => {
    try {
      const res = await forumApi.forumStatsList(course);

      const data = res.data as unknown as ForumStats;

      if (data) {
        forumStats.value = {
          unread_forum_count: data.unread_forum_count,
          unread_announcements_count: data.unread_announcements_count,
          unread_total_count: data.unread_total_count,
        };
      }
    } catch (error) {
      console.error("Error fetching unread counts:", error);
    }
  };

  const updateUnreadCount = (count: number, type: keyof ForumStats) => {
    forumStats.value[type] = count;
  };

  const toggleNotifications = () => {
    showNotifications.value = !showNotifications.value;
  };

  const getUnreadCount = () => forumStats.value;

  return {
    forumStats,
    updateUnreadCount,
    toggleNotifications,
    getUnreadCount,
    fetchForumStats,
  };
});
